import React from "react"
import Loading from "../../components/Loading"
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from "@material-ui/core"
import Coins from "./Coins"
import { withTranslation } from "react-i18next"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import styled from "styled-components"

const StyledTypography = styled(Typography)`
  margin-bottom: 0.5rem !important;
`

const ExtraDetails = ({ exerciseDetails, onUpdate, nocoins, t }) => {
  return (
    <div>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            {
              <p>
                <small>Wie reiche ich meine Lösung ein?</small>
              </p>
            }
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div>
            <StyledTypography>
              <small>
                Sie müssen hierfür den Artemis Server verwenden und die
                entsprechende Aufgabe lösen.
              </small>
              <a
                href="https://artemis.techfak.de"
                rel="noopener noreferrer"
                target="_blank"
              >
                {
                  <p>
                    <small>Hier klicken.</small>
                  </p>
                }
              </a>
            </StyledTypography>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

export default withTranslation("common")(ExtraDetails)
