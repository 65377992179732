import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons"

const greenAccentColor = "#4CAF50" // Green color

const QuizWrapper = styled.div`
  margin: 2rem 0;
  border-left: 0.2rem solid ${greenAccentColor};
  border-radius: 1rem; /* Ensuring rounded corners */
  box-shadow: 0 8px 40px -12px rgba(0, 0, 0, 0.3);
  padding: 0 !important;
  background-color: #f7f7f9;
  overflow: hidden; /* Ensures the content respects the border-radius */
`

const Header = styled.div`
  font-size: 1.3rem;
  font-weight: normal;
  padding: 1rem;
  background-color: ${greenAccentColor};
  color: white;
  display: flex;
  align-items: center;
  border-radius: 0.8rem 0.8rem 0 0; /* Top corners rounded */
`

const Icon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`

const Body = styled.div`
  padding: 1rem;
  border-radius: 0 0 1rem 1rem; /* Bottom corners rounded */
`

const Quiz = ({ url, children }) => {
  const defaultUrl = "https://pdp-quizzes.algochem.techfak.de/quizzes.md"
  const [urlExists, setUrlExists] = useState(null)

  useEffect(() => {
    const finalUrl = url || defaultUrl

    // Function to check if URL is reachable
    const checkUrl = async () => {
      try {
        const response = await fetch(finalUrl, { method: "HEAD" })
        if (response.ok) {
          setUrlExists(true)
        } else {
          setUrlExists(false)
        }
      } catch (error) {
        console.error("Error fetching URL:", error)
        setUrlExists(false)
      }
    }

    checkUrl()
  }, [url])

  return (
    <QuizWrapper>
      <Header>
        <Icon icon={faQuestionCircle} /> Quiz
      </Header>
      <Body>
        {urlExists === null ? (
          <p>Loading...</p>
        ) : urlExists ? (
          <>
            <a
              href={url || defaultUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {children || "Quiz"}
            </a>
            <p>
              [
              <a
                href={url || defaultUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {url || defaultUrl}
              </a>
              ]
            </p>
          </>
        ) : (
          <p>Dieser Link funktioniert nur während der Vorlesung</p>
        )}
      </Body>
    </QuizWrapper>
  )
}

export default Quiz
